import React, { useState } from 'react';
// Style
import '../styles/Accueil.scss';
//image
import MinionOpen from '../images/Accueil/Minionopen.svg';
import MinionClose from '../images/Accueil/Minionclose.svg';
import Présentation from '../images/Accueil/Présentation.svg';
import MesCompetences from '../images/Accueil/MesCompetences.svg';
import MesRéseaux from '../images/Accueil/MesRéseaux.svg';
import HoverTheTop from '../images/Accueil/HoverTheTop.svg';
import HTTPLink from '../images/Accueil/HTTPLink.svg';
import PHOTOdemoi from '../images/Accueil/PHOTODEMOI.jpg';
import Discord from '../images/Accueil/Discord.svg';
import Malt from '../images/Accueil/Malt.svg';
import Facebook from '../images/Accueil/logoFacebook.svg';

import Linkedin from '../images/Accueil/Linkedin.svg';
import Nav from '../component/Nav';


export default function Accueil() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
    if (!isOpen1) {
      setIsOpen2(false);
      setIsOpen3(false);
    }
  };

  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
    if (!isOpen2) {
      setIsOpen1(false);
      setIsOpen3(false);
    }
  };

  const toggleAccordion3 = () => {
    setIsOpen3(!isOpen3);
    if (!isOpen3) {
      setIsOpen1(false);
      setIsOpen2(false);
    }
  };

  return (
    
    <div className="All-accueil">
      <Nav />
        <div className='prez'>
            
            <div className='photodemoi'>
                <img src={PHOTOdemoi} alt='Photodemoi'></img>
            </div>
            <div className='logo-prez'>
                <img src={Présentation} alt="Présentation"></img>
                  <p>Développeur Freelance, spécialisé dans le FrontEnd, je vends mes services afin de créer votre site internet en fonction de vos besoins.
                    Travaillant en collaboration avec <a href='http://www.eminencegraphique.fr' alt='site du graphiste'>Eminence Graphique</a>, nous voulons vous offrir la qualité de service clef en main. 
                    Que ce soit de l'étape de la reflexion à l'hébergement final de votre projet, nous répondrons présent. <br></br><br></br> Je propose également des services de creation Excel et Sheet. 
                  </p>
            </div>
        </div>

      <div className="accordion">
        <div className="accordion-item">
          <div className="accordion-header" onClick={toggleAccordion1}>
            <img src={isOpen1 ? MinionClose : MinionOpen} alt="Minion Toggle" className="accordion-image accordion-image-left" />
            {isOpen1 ? null : <h3 className="accordion-title-left">Mon Savoir-faire</h3>}
          </div>
          {isOpen1 && (
            <div className="accordion-content bloc-savoir-faire">
              <img src={MesCompetences} alt='img-compétences'></img>
              <p>
                Spécialisé dans le FrontEnd, mon travail est de vous satisfaire sur l'accessibilité, la visibilité, l'interactivité et le reférencement de votre site, afin de permettre une visibilité plus que prometteuse sur internet. Je propose aussi mes services dans la création de tableaux Excel / Sheet pour tout vos besoin de gestion, listing, graphiques, statistique ... . 
              </p>
            </div>
          )}
        </div>

        <div className="accordion-item">
          <div className="accordion-header" onClick={toggleAccordion2}>
            <img src={isOpen2 ? MinionClose : MinionOpen} alt="Minion Toggle" className="accordion-image accordion-image-center" />
            {isOpen2 ? null : <h3 className="accordion-title">MES RÉSEAUX</h3>}
          </div>
          {isOpen2 && (
            <div className="accordion-content ">
              <div className='bloc-réseaux'>
                <img src={MesRéseaux} alt='img-réseaux'></img>
              </div>
              <div className='logo-reseaux'>
                <a href='https://discord.gg/ABJmSxTrXH' target='_blanck'><img src={Discord} alt='logo-discord'/></a>
                <a href='https://www.malt.fr/profile/jeremyvigier?' target='_blanck'><img src={Malt} alt='logo-Malt'/></a>
                <a href='https://www.linkedin.com/in/jeremy-vigier/' target='_blanck'><img src={Linkedin} alt='logo-linkedin'/></a>
                <a href='https://www.facebook.com/profile.php?id=61566617814378' target='_blanck'><img src={Facebook} alt='logo-facebook'/></a>
             </div>
            </div>
          )}
        </div>

        <div className="accordion-item">
          <div className="accordion-header" onClick={toggleAccordion3}>
            {isOpen3 ? null : <h3 className="accordion-title-right">Hover The Top</h3>}
            <img src={isOpen3 ? MinionClose : MinionOpen} alt="Minion Toggle" className="accordion-image accordion-image-right" />
          </div>
          {isOpen3 && (
            <div className="accordion-content bloc-http">
              
                <img  className='image-http' src={HoverTheTop} alt='img-http'></img>
              
              <p>
              est un <span>partenariat</span> entre un 
                graphiste et un développeur,<br></br> afin
                de vous proposer <span>le meilleur du 
                web design !</span>
                <br></br>
                Si vous cherchez à <span>créer</span> ou             
                 <span> améliorer</span> votre site,<br></br>
                n’hésitez pas à <span>visiter notre
                page</span> et à nous contacter !
              </p>
          
              <a href='https://hoverthetop.fr'><img src={HTTPLink} alt='lien-http' target='_blanck'rel="noreferrer"/></a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
